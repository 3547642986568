import axios from 'axios';

export const api = axios.create({
    baseURL: 'https://web.hwil.com.cn/api'     //'http://192.168.0.148:7070'//'http://192.168.0.148:7070' //'https://web.hwil.com.cn/api'
});
export const dev = axios.create({
    baseURL: 'https://dev.qianhanghui.com/prod-api' //'https://dev.qianhanghui.com/prod-api'//'http://192.168.0.148:7070'//'http://192.168.0.148:7070'
});
export const jw = axios.create({
    baseURL: 'https://mkt.qianhanghui.com/prod-api'  //'http://192.168.50.171:10015'
});
