
import { defineComponent, reactive, toRefs, computed, watch } from 'vue';
import TUIConversationList from './components/list';
import { caculateTimeago, isArrayEqual } from '../utils';
import { handleAvatar, handleName, handleShowLastMessage, handleAt } from '../TUIChat/utils/utils';
import {api, dev, jw} from '@/config';

const TUIConversation = defineComponent({
  name: 'TUIConversation',

  components: {
    TUIConversationList,
  },
  props: {
    displayOnlineStatus: {
      type: Boolean,
      default: false,
    },
  },
  setup(props: any, ctx: any) {
    const TUIServer: any = TUIConversation?.TUIServer;
    const data = reactive({
      currentConversationID: '',
      conversationData: {
        list: [],
        handleItemAvator: (item: any) => handleAvatar(item),
        handleItemName: (item: any) => handleName(item),
        handleShowAt: (item: any) => handleAt(item),
        handleShowMessage: (item: any) => handleShowLastMessage(item),
        handleItemTime: (time: number) => {
          if (time > 0) {
            return caculateTimeago(time * 1000);
          }
          return '';
        },
      },
      userIDList: new Set(),
      netWork: '',
      env: TUIServer.TUICore.TUIEnv,
      displayOnlineStatus: false,
      userStatusList: TUIServer.TUICore.TUIServer.TUIContact?.currentStore?.userStatusList,
    });

    TUIServer.bind(data);

    TUIConversationList.TUIServer = TUIServer;
       // 监听数组变化
    watch( () => data.conversationData.list, (newValue, oldValue) => {
      //const userID =localStorage.getItem('userID');
      const userID: string | null = localStorage.getItem('userID');
      if (userID && userID.includes("dev")) {
        dev.get(`/api/im/kefuUnique?phone=${userID}`).then(res=>{
          if(res.data.data==0){
            TUIServer.handleCurrentConversation(newValue[0]);
          }
        })
      }else if (userID && userID.includes("jw")) {
        jw.get(`/system/api/im/kefuUnique?phone=${userID}`).then(res=>{
          if(res.data.data==0){
            TUIServer.handleCurrentConversation(newValue[0]);
          }
        })
      }else{
        api.get(`/api/im/kefuUnique?phone=${userID}`).then(res=>{
          if(res.data.data==0){
            TUIServer.handleCurrentConversation(newValue[0]);
          }
        })
      }
    })


    watch(
      () => data.currentConversationID,
      (newVal: any) => {
        ctx.emit('current', newVal);
      },
      {
        deep: true,
      }
    );

    watch(
      () => props.displayOnlineStatus,
      async (newVal: any, oldVal: any) => {
        if (newVal === oldVal) return;
        data.displayOnlineStatus = newVal;
        TUIServer.TUICore.TUIServer.TUIContact.handleUserStatus(data.displayOnlineStatus, [...data.userIDList]);
        data.userStatusList = TUIServer.TUICore.TUIServer.TUIContact?.currentStore?.userStatusList;
      },
      { immediate: true }
    );

    watch(
      () => [...data.userIDList],
      async (newVal: any, oldVal: any) => {
        if (isArrayEqual(newVal, oldVal)) return;
        TUIServer.TUICore.TUIServer.TUIContact.handleUserStatus(data.displayOnlineStatus, [...data.userIDList]);
      },
      {
        deep: true,
      }
    );

    const isNetwork = computed(() => {
      const disconnected = data.netWork === TUIServer.TUICore.TIM.TYPES.NET_STATE_DISCONNECTED;
      const connecting = data.netWork === TUIServer.TUICore.TIM.TYPES.NET_STATE_CONNECTING;
      return disconnected || connecting;
    });

    const handleCurrentConversation = (value: any) => {
      TUIServer.handleCurrentConversation(value);
    };

    return {
      ...toRefs(data),
      handleCurrentConversation,
      isNetwork,
    };
  },
});
export default TUIConversation;
