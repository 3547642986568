import { createApp } from 'vue'
import App from './App.vue'
import { TUIComponents, TUICore, genTestUserSig } from './TUIKit';

window.addEventListener('message', messageEvent=> {
  if(messageEvent.source!=window.parent.window.parent) return;
  //  console.log('收到vue的数据：',messageEvent.data);
  let SDKAppID = messageEvent.data.appId; // Your SDKAppID
  let userID = messageEvent.data.userId; //Your secretKey
  let userSig = messageEvent.data.userSig; // User ID
  const nickName = messageEvent.data.nickName; // User nickName
  const avatar = messageEvent.data.avatar; // User avatar

  if(userID == undefined){
    const secretKey = '71428c48911c2e1209484a262a13488d2bf784a686cd837c93f5e05d2775d56a'; //Your secretKey
    SDKAppID = 1400804311; // Your SDKAppID
    userID = '18888888888'; // User ID
    userSig = genTestUserSig({
      SDKAppID,
      secretKey,
      userID,
    }).userSig;
    // console.log(userSig);
  }
   // 存储缓存
   localStorage.setItem('userID', userID);
  // init TUIKit
  const TUIKit = TUICore.init({
    SDKAppID,
    userID,
    nickName,
    avatar,
  });
  
// TUIKit add TUIComponents
  TUIKit.use(TUIComponents);

// login TUIKit
  TUIKit.login({
    userID: userID,
    userSig: userSig, // The password with which the user logs in to IM. It is the ciphertext generated by encrypting information such as userID.For the detailed generation method, see Generating UserSig
  });
  createApp(App).use(TUIKit).mount('#app')

});


/*const SDKAppID = 1400794033; // Your SDKAppID
const secretKey = '02e4ce078ff3a249778cf6ee98d4825a1ee17ac4b11ae986b7847b82618db09d'; //Your secretKey
const userID = '1'; // User ID
const nickName = '温柔体贴';
const avatar = 'http://sta2.hi1024.com/njust/upload/pub/20211201/42751638329313829/wallhaven-m9y7v1.jpg';
// init TUIKit
const TUIKit = TUICore.init({
    SDKAppID,
    userID,
    nickName,
    avatar,
});
// TUIKit add TUIComponents
TUIKit.use(TUIComponents);

// login TUIKit
TUIKit.login({
    userID: userID,
    userSig: genTestUserSig({
        SDKAppID,
        secretKey,
        userID,
    }).userSig,
});
const app = createApp(App);
app.use(TUIKit).mount('#app')*/




// eslint-disable-next-line @typescript-eslint/no-empty-function
/*
 //import axios from "axios";
  axios.get("http://192.168.50.171:7070/imSigInfo?userId="+userID).then(res=>{
  console.log(res.data);
});*/
